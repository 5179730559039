@import '_postcss-vars.css';
@import '_normalize.css';
@import '_wordpress.css';
@import '_show-hide.css';
@import '_header.css';
@import '_tooltip.css';
@import '_box.css';

@custom-media --viewport-sm-max only screen and (max-width: 768px);
@custom-media --viewport-md-max only screen and (max-width: 992px);
@custom-media --viewport-lg-max only screen and (max-width: 1200px);

html {
  line-height: 1.25;
}

/* Remove horizontal blank space */
.full-width .expand {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 980px) {
  .clf7 .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* Reset CLF links */
.clf7 a, .clf7 a:hover, .clf7 a:link, .clf7 a:visited {
  font-weight: initial;
  border-bottom: none;
}

/* RAP namespace and containers */
.rap {
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    padding: 0;
}

.rap *, .rap *:before, .rap:after {
    box-sizing: inherit;
}

.rap-content {
    font-family: Arial, Helvetica, sans-serif;
    padding: 1em 0 3em 0;
}

body{
    font-family: Arial, Helvetica, sans-serif;
    margin:0;
    padding:0;
    background:#fff!important;
}

a {
    text-decoration: none;
    color: var(--color-links);
    font-weight: bold;
    cursor: pointer;
    overflow-wrap: break-word;
    word-break: break-word;
    word-wrap: break-word;
}

a:hover {
    text-decoration: underline;
}

.rap-content a:visited:not(.btn):not([type=button]) {
    color: #925cad;
}

p {
    color: var(--color-text);
}

button {
    cursor: pointer;
}

input[placeholder] {
    text-overflow: ellipsis;
}

/*--Generic Styles--*/
.margin-bottom-none {
    margin-bottom: 0;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}
.margin-top-none{
    margin-top: 0;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-top-30{
    margin-top: 30px;
}
.margin-top-05 {
    margin-top: 5px;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-top-20{
    margin-top: 20px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.margin-right-05 {
    margin-right: 5px;
}
.margin-right-10 {
    margin-right: 10px;
}
.padding-20 {
    padding: 20px;
}

.space-bottom-05 {
    margin-bottom: 0.5em;
}

.space-bottom-1 {
    margin-bottom: 1em;
}

.space-bottom-2 {
    margin-bottom: 2em;
}

.space-top-05 {
    margin-top: 0.5em;
}

.space-top-1 {
    margin-top: 1em;
}

.space-top-2 {
    margin-top: 2em;
}

.space-right-05 {
    margin-right: 0.5em;
}

.text-center {
    text-align: center;
}

.margin-centered {
    margin: 0 auto;
}

.justify-center {
    justify-content: center;
}

.hidden {
  display: none;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

a.disabled {
  color: gray;
  text-decoration: none;
}

hr {
    height:2px;
    background-color:#000;
}

ol li {
    margin-top:30px;
}

.float-left{
    float:left;
}

.clear-both{
    clear:both;
}

.alert {
    padding: 15px;
}

.center-container {
  padding: 1em 0;
}

.main-container, .center-container, .nav-container {
  margin: auto;
  width: 940px;
  position: relative;
}

/*--Align contents middle to page--*/
@media only screen and (min-width: 1200px) {
  /*--Center align to page--*/
  .main-container, .center-container, .nav-container {
    width:1170px;
  }
}

@media screen and (max-width: 980px) {
  .main-container, .center-container {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .nav-container {
    width: 100%;
  }
}

/*--Top Nav styles--*/

@media only screen and (min-width: 1300px) {
  .topnav {
    overflow: hidden;
    background-color: #000;
    padding: 0;
  }
  }

.topnav a {
  float: left;
  display: block;
  color: #fff;
  text-align: center;
  line-height:2.4rem;
  padding: 0 1.2rem 0 1.2rem;
  border:0;
  margin:0;
  text-decoration: none;
  font-size: .8rem;
}
.topnav a:hover {
 text-decoration: none;
}

.topnav .icon {
  display: none;
}

/*--Break-point--*/


@media screen and (min-width: 900px) {
  .topnav {
    height:2.4rem;
  }
}

.topnav {
  overflow: hidden;
  background-color: #000;
}

@media screen and (max-width: 900px) {
  .topnav a:not(:first-child), .dropdown .dropbtn {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }
}
.admin-nav-item a{
  color:#F5D98D;
  float:right;
}
@media screen and (max-width: 900px) {
    .admin-nav-item a {
        display:none;
    }
}

/*----*/

  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }


/* BACKGROUND COLORS */

.blue {
    background: var(--blue);
}

.green {
    background: var(--green);
}

.light-teal {
  background: var(--light-teal);
}

.light-blue {
  background: var(--light-blue);
}

.purple {
  background: var(--purple);
}

.orange {
    background: var(--orange);
}

.light-green {
    background: var(--light-green);
}

.light-purple {
  background: var(--light-purple);
}

.yellow {
    background: var(--yellow);
}

.teal {
    background: var(--teal);
}


.alert-success {
    background: var(--color-success);
    border: 1px solid var(--color-success-dark);
    color: var(--color-success-dark);
}

.alert-success strong {
    color: var(--color-success-dark);
}

.alert-error {
    background: var(--color-error);
    border: 1px solid var(--color-error-dark);
    color: var(--color-error-dark);
}

.alert-error strong {
  color: var(--color-success-dark);
}

.loading {
  color: #7a7a7a;
  width: 100%;
  text-align: center;
  opacity: 0;
  animation-name: pulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Buttons */
.btn {
    background-color: white;
    color: black;
    display: inline-block;
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    text-decoration: none;
    vertical-align: middle;
    transition: var(--animation-btn-hover);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: bold;
    font-size: 1em;
    border-radius: 0.3em;
}

.btn.btn-sm {
  font-size: 0.75rem;
}

.btn-dark, .btn.search-btn{
  background-color: var(--color-dark-bg);
  border: 2px solid var(--color-dark-border);
  color: var(--color-dark-text)
}

/* secondary button styles */

.btn.btn-light
{
  background-color: var(--color-light-bg);
  border: 2px solid var(--color-light-border);
  color: var(--color-light-text);
}

a.btn:hover, .btn:hover {
  background-color: var(--color-light-hover);
  border: 2px solid var(--color-dark-border);
}

a.btn.btn-dark:hover, .btn.btn-dark:hover, .btn.search-btn:hover {
  color: black;
  /*background-color: var(--color-btn-dark-hover);*/
}

a.btn:active, .btn:active, .btn.btn-dark:active, .btn.search-btn:active {
  background-color: var(--color-btn-active);
}

a.btn, a.btn:hover, .btn a, .btn a:hover {
  text-decoration: none;
}

button.btn:disabled, button.btn:disabled:hover
a.btn:disabled, a.btn:disabled:hover {
  background-color: var(--color-lightgray);
  color: var(--color-midgray);
  border-color: var(--color-lightgray);
  cursor: not-allowed;
}

/* for inputs */
.main-container label {
    margin-bottom:0px;
    display:block;
    margin:10px 0 4px 0;
}

.main-container input,
.main-container select,
.main-container textarea {
    border: 2px solid #000;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.3rem 0.5rem 0.1rem 0.5rem;
    width: 100%;
}

/* Handle heights */
.main-container input[type="text"],
.main-container input[type="email"],
.main-container select:not([multiple]) {
    height: calc(1rem * 1.5 + 0.4rem + 6px); /* Match font-size * line-height + padding-top + padding-bottom + 2 * border */
}

.main-container textarea {
    min-height: 100px;
}

.main-container select {
    background-color: white;
    font-family: sans-serif;
    border-radius: 0rem;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23222222%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    overflow: hidden;
    padding-right: 1.6em;
}

.main-container select::-ms-expand {
    display: none;
}

.main-container input[type="text"]:disabled,
.main-container input[type="text"]:read-only,
.main-container textarea:disabled,
.main-container textarea:read-only,
.main-container select:disabled {
    background-color: #dedede;
}

.main-container .input-error {
  border-color: red;
}

.main-container .input-error-msg {
  color: red;
  font-size: .8em;
}

.breadcrumbs{
    font-size:0.85em;
    margin:10px 0 30px 0;
    padding:0;
}

.breadcrumbs span.trail-begin {
    display: inline-block;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
}

@media (--viewport-sm-max) {
  .breadcrumbs span.trail-begin {
      max-width: 80%;
  }
}

.breadcrumbs .divider {
    display: inline-block;
    vertical-align: middle;
}

/*--News sections-*/
.latest-container{
    padding-top:10px;
}

.news-container a, .latest-container a{
    margin-top:5px;
    margin-bottom:0;
}

.news-container b, .latest-container b{
    color:#000;
}

.news-date {
    margin-bottom: 0.75em;
}

.latest-container .fa{
    color:#000;
}

.article-image{
  margin: 0 20px 5px 0;
  width: 38%;
}

@media only screen and (max-width: 540px) {
  .article-image {
    width:100%;
  }
}

.related-materials-box-body {
    min-height: 150px;
}

.related-materials-footer {
    align-items: center;
    display: flex;
    height: 80px;
    justify-content: space-between;
    max-height: 80px;
    overflow-y: hidden;
}

.related-materials-footer .pull-right {
    max-width: 40%;
}

@media (--viewport-lg-max) {
    .related-materials-box-body {
        min-height: 100px;
    }
}

@media (--viewport-md-max) {
    .related-materials-box-body {
        min-height: 75px;
    }
}

.topics-box-body {
    min-height: 150px;
}

.projects-box-body {
    min-height: 260px;
}

.zoom-img img{
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
}

.zoom-img.learn{
    max-height: 250px;
    overflow:hidden;
}

a.click-more {
    display:inline-block;
    margin-top:10px;
    color:#000;
    text-decoration:none;
    border-bottom:3px solid #000;
}

@media only screen and (min-width: 600px) {
    .twitter-box{
        font-size:1em;
    }
}

.inline-form, input.inline-form, label.inline-form {
    width: auto;
    display: inline-block;
}

/*--object page--*/

.object-box {
    padding: 35px;
}

.border-black{
  border:3px solid #000;
}

.border-med {
    border:2px solid #6f6f6f;
}

.border-sm {
    border:1px solid #6f6f6f;
}

.border-grey{
  border:3px solid #E9E9E9;
}

.fix-height{
  margin:10px 0 10px 0;
  height:400px;
  position:relative;
}

.twitter-box{
    border:1px solid #000;
    border-radius: 3px;
    font-size:.8em;
    padding-top:10px;
    height:280px;
    overflow-x: auto;
}

a.active {
    color:#000;
}
/* screen read only */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0px;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
/*--Search bar--*/
.search-container {
    text-align: center;
}

input.search {
    border: 2px solid #000;
    height: 2.2rem !important;
    display: inline-block;
    vertical-align: middle;
    width: 75%;
}


button.search, button.search-btn {
    border-radius: 0;
    height: 2.2rem !important;
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
    padding: 0 4%;
    vertical-align: middle;
}


@media only screen and (max-width: 900px) {
    input.search {
        width: 70%;
    }
    button.search {
        width: 25%;
    }
}

.tab {
    display: flex;
}

.tab-button {
    align-items: center;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    min-height: 2.5em;
    margin-top: 1em;
    padding: 1em;
    transition: var(--animation-btn-hover);
}

.tab-button a {
    text-decoration: none!important;
    border-bottom: none;
    color: #000;
}

/* Change background color of buttons on hover */
.tab-button:hover {
    background-color: var(--color-btn-hover);
    cursor: pointer;
}

/* Create an active/current tablink class */
.tab-button.active {
    background-color: var(--color-light-bg);
    border-bottom: none;
    border-left: var(--tab-border);
    border-right: var(--tab-border);
    border-top: var(--tab-border);
}

.tab-button:not(.active) {
    border-bottom: var(--tab-border);
    border-left: none;
    border-right: none;
    border-top: none;
}

@media (--viewport-sm-max) {
    .tab {
        justify-content: space-between;
    }

    .tab-button {
        font-size: 0.8em;
        padding: 0.5em;
        min-height: 7em;
    }

    .tab-button.col-xs-6 {
        flex-basis: 48%;
	-ms-flex-preferred-size:44%;
    }

    .tab-button.col-xs-4 {
        flex-basis: 32%;
	-ms-flex-preferred-size:27%;
    }

    .tab-button.active {
      border-bottom: var(--tab-border);
      border-left: var(--tab-border);
      border-right: var(--tab-border);
      border-top: var(--tab-border);
    }

    .tab-button:not(.active) {
        border-bottom: var(--tab-border-thin);
        border-left: var(--tab-border-thin);
        border-right: var(--tab-border-thin);
        border-top: var(--tab-border-thin);
    }
}

.tabcontent {
    display: none;
    padding: 30px 0 0 0;
    border-top: none;
}

.text-overflow-ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

a h4 .card .news-date {
    display: block;
    font-size: 0.83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.news-date.no-link {
    display: block;
    font-size: 0.83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: black;
}

