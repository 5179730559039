/**
 * This file captures styles coming from WordPress,
 * so that rendering content will appear similar
 * to what is shown in the WordPress visual editor.
 */
.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alignleft {
  float: left;
  margin: 0 1em 0.5em 0;
}

.alignright {
  float: right;
  margin: 0 0 0.5em 1em;
}

.wp-caption-text {
  font-size: 0.8em;
  font-weight: 600;
}

@media only screen and (max-width: 540px) {
  .aligncenter, .alignleft, .alignright {
    height: auto;
    margin-bottom: 1em;
    width: 100%;
  }
}

