/* reusable css vars using postcss-preset-env */
:root {
    /* MENU COLORS (in menu usage order) */
    --blue: #329EAB;  /* home & admin */
    --green: #6EBA81; /* topics */
    --teal: #61a893; /* search & objects */
    --light-blue: #66becc; /* directory */
    --purple: #727cb3; /* projects */
    --orange: #e68746; /* resources */
    --light-green: #87d499; /* about */
    --light-teal: #7ab99d; /* news */
    --light-purple: #8f98d3; /* contact */
    --yellow: #FFD076; /* help */

    /* -- old colors --
    --blue: #479EAC;
    --faded-blue: #A2CCCA;
    --green: #7BCB92;
    --faded-green: #8AB286;
    --orange: #F29D33;
    --light-blue: #80CAC7;
    --light-green: #64C776;
    --yellow: #F5D98D;
    --teal: #317e7e;
       ---- */

    /*-- OTHER COLORS -- */
    --color-offwhite: #fafafa;
    --color-gainsboro: #dcdcdc;
    --color-lightgray: #cbcbcb;
    --color-midgray: #7a7a7a;
    --color-darkgray: #545454;

    --color-text: #060606;
    --color-links: #045F71;

    --color-dark-bg: black;
    --color-dark-border: black;
    --color-dark-text: white;

    --color-light-hover: #dce6f6;
    --color-light-bg: #eff2f9;
    --color-light-border: var(--color-darkgray);
    --color-light-text: black;

    --color-btn-hover: #adc8e9;
    --color-btn-active: white;
    --color-btn-dark-hover: var(--color-light-bg);

    --color-filter-bg: #F0F5FB;
    --color-filter-text: #0E7E9E;
    --color-filter-hover-bg: #DBDBDB;

    --color-success: #DFF0D8;
    --color-success-dark: #3C763D;
    --color-error: #F2DEDE;
    --color-error-dark: #A94442;

    /* -- OTHER -- */
    --animation-btn-hover: all 0.3s ease-out;
    --tab-border: 3px solid black;
    --tab-border-thin: 2px solid black;
}
