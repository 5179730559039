.header .logo {
  margin-right: 5px;
}

.header h1, .header h2 {
  font-size: 2.2rem;
  margin: 0;
  padding: 0;
}

.header-content-container {
  margin: 0.5em 0;
}

/* Home */

.header.home .header-content-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.header.home .logo-text {
  display: flex;
  flex-direction: column;
}

.header.home h2 {
  font-size: 1.2rem;
  font-weight: normal;
}

.header.home img {
  margin-right: 10px;
  width: 4.75em;
  max-height: 5em;
}

/* Subpage */

.header.sub-page .header-content-container {
  display: flex;
  justify-content: space-between;
}

.header.sub-page h1 {
  align-items: center;
  display: flex;
}

.header.sub-page .brand {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.header.sub-page .brand h2 {
  white-space: nowrap;
}

.header.sub-page .logo {
  font-size: 1.2rem;
  max-width: 65px;
}

.header .brand h2 {
  font-size: 1.6rem;
}

@media screen and (max-width: 1200px) {
  .header.home h1 {
    font-size: 1.6rem;
  }

  .header.home h2 {
    font-size: 1rem;
    line-height: 1.6rem;
  }

  .header.home img {
    width: 55px;
  }

  .header.sub-page h1 {
    font-size: 2.2rem;
  }

  .header.sub-page .brand h2 {
    font-size: 1.2rem;
  }

  .header .brand img {
    height: 50px;
  }
}

@media screen and (max-width: 780px) {
  .header.home img {
    width: 50px;
  }

  .header.home h1 {
    font-size: 1rem;
    height: 20px;
  }

  .header.home h2 {
    font-size: 1rem;
  }

  .header.sub-page h1 {
    font-size: 1.8rem;
  }

  .header.sub-page .brand h2 {
    font-size: .9rem;
  }
}

@media screen and (max-width: 600px) {
  .header.home img {
    width: 50px;
  }

  .header.home h1 {
    font-size: .9rem;
    height: 20px;
  }

  .header.home h2 {
    font-size: .8rem;
  }

  .header.sub-page h1 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 500px) {
  .header.home img {
    width: 30px;
  }

  .header.home h1 {
    font-size: .9rem;
  }

  .header.home h2 {
    font-size: .8rem;
    display: none;
  }

  .header.sub-page h1 {
    font-size: 1.2rem;
  }
}

.admin-bar {
  box-sizing: border-box;
  background-color: #F5D98D;
  font-size: 0.8em;
  padding: 0.5em 0;
}

.admin-bar .nav-container {
  padding: 3px 20px;
  justify-content: flex-end;
}

.admin-bar .nav-container .wp-opts {
  margin-right: 20px;
}

.wp-opts .pipe {
  margin: 0 0.5em;
}

.admin-bar a {
  color: black;
}
.admin-bar a:hover {
  text-decoration: underline;
}
.logout-btn{
  margin-left: 20px;
  margin-right: 0;
}
