.show-hide-footer {
    font-size: 0.9em;
    margin-top: 0.5em;
    display: inline-block;
    width: 100%;
}

.show-hide-content, .text-truncate  {
    transition: all 1s ease-out;
    max-height: 2000px;
    overflow: hidden;
}
.show-hide-content.hidden {
    max-height: 0;
    display: block;
}

.text-truncate.truncated {
    max-height: 5.1em;
    position: relative;
}
.text-truncate.truncated:after {
    content: "...";
    position: absolute;
    right: 1em;
    top: 4.1em;
}