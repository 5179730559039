.box.border-black {
  border: 3px solid #000;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 70px;
}

.box h3{
  margin:0 0 15px 0;
  padding:0;
}

.box h4{
  margin:0 0 15px 0;
  padding:0;
}

.box h5{
  margin:0 0 10px 0;
  padding:0;
}

.box p {
  margin-top:6px;
  margin-bottom:0;
  font-size: 0.9em;
}

.box span {
  font-size: 0.8em;
}

.box p span.circle {
  font-size: 1.3em;
  line-height: 2.3em;
  height: 2.3em;
  min-width: 2.3em;
  background-color: var(--color-text);
  color: white;
  border-radius: 50%;
  margin-right: 0.5em;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  transition: var(--animation-btn-hover);
}

.box.box-linked:hover p span.circle {
  background-color: var(--color-links);
}

.stretched-link::after {
  bottom: 0;
  content: "";
  cursor: pointer;
  left: 0;
  pointer-events: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.box-linked {
  position: relative; /* Required so that .stretched link can fill the area */
  transition: var(--animation-btn-hover);
  background-color: var(--color-offwhite);
}

.box-linked:hover {
  background-color: white;
  border-color: var(--color-links);
}

.box-linked a:not(.stretched-link),
.box-linked button,
.box-linked .hoverable {
  position: relative;
  z-index: 100; /* Ensure hoverable content goes up top */
}

.box-body {
  padding: 1.25rem;
}

.box-footer {
  padding: 0.75rem 1.25rem;
}
