.popper,
.tooltip {
  position: absolute;
  background: #ffc107;
  color: black;
  min-width: 150px;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  padding: 10px;
  text-align: center;
}

.popper .popper__arrow,
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 10px;
}

.tooltip .tooltip-arrow,
.popper .popper__arrow {
  background: transparent;
  border-color: black;
}

.popper[x-placement^="top"],
.tooltip[x-placement^="top"] {
  margin-bottom: 10px;
}

.popper[x-placement^="top"] .popper__arrow,
.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 10px 10px 0 10px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -10px;
  left: calc(50% - 10px);
  margin-top: 0;
  margin-bottom: 0;
}

.popper[x-placement^="bottom"],
.tooltip[x-placement^="bottom"] {
  margin-top: 10px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow,
.popper[x-placement^="bottom"] .popper__arrow {
  border-width: 0 10px 10px 10px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: -10px;
  left: calc(50% - 10px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"],
.popper[x-placement^="right"] {
  margin-left: 10px;
}

.popper[x-placement^="right"] .popper__arrow,
.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 10px 10px 10px 0;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  left: -10px;
  top: calc(50% - 10px);
  margin-left: 0;
  margin-right: 0;
}

.popper[x-placement^="left"],
.tooltip[x-placement^="left"] {
  margin-right: 10px;
}

.popper[x-placement^="left"] .popper__arrow,
.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 10px 0 10px 10px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  right: -10px;
  top: calc(50% - 10px);
  margin-left: 0;
  margin-right: 0;
}

/* Custom */
.popper,
.tooltip {
  background-color: white;
  border: 2px solid black;
  border-radius: 0.3em;
  padding: 0.75em;
  min-width: 100px;
  max-width: 200px;
}

.tooltip-trigger {
  cursor: pointer;
}

.tooltip-arrow {
  background-color: black;
}
